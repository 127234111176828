import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {WebserviceService} from '../webservice.service';

@Component({
  selector: 'app-bag-history',
  templateUrl: './bag-history.component.html',
  styleUrls: ['./bag-history.component.css']
})
export class BagHistoryComponent implements OnInit {
  bag : object
  constructor(private formBuilder: FormBuilder, private route : ActivatedRoute, private webservice:WebserviceService, private router: Router) {
    this.route.params.subscribe( params =>{
        // console.log(params)
        if(params.bagId != undefined){
          this.moreInfoBag(params.bagId)
        }
      }


    );
  }

  moreInfoBag(bagId){
    var req = {}
    req["bagId"] = bagId
    this.webservice.moreInfoBag(req).subscribe(data=>{
      this.bag = data


      //console.log(bagPerSegment)

    },error =>{

    })
  }
  ngOnInit() {
  }

}
