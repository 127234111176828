import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import {ActivatedRoute, Router} from '@angular/router';
import {WebserviceService} from '../webservice.service';
import {BagService} from '../bag.service';
import {BagModel} from '../bag-model';
import { UUID } from 'angular2-uuid';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {
  order : any
  outbound : any
  inbound  : any
  currentBag  : BagModel
  bagForm : FormGroup
  public base64Image: string;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  constructor(private formBuilder: FormBuilder, private route : ActivatedRoute, private webservice:WebserviceService, private router: Router, private bagservice: BagService) {
    this.bagForm = this.formBuilder.group({
      width: ['0', Validators.required],
      height: ['0', Validators.required],
      depth: ['0', Validators.required],
      weight: ['0', Validators.required],

    });

    this.route.params.subscribe( params =>{
        // console.log(params)
        //this.pickupForm.controls["bookingId"].setValue(params.bookingId)
        if(params.orderId != undefined){
          this.retrievePickupOrder(params.orderId)
        }
      }


    );
  }

  ngOnInit() {
    var odrs = [{"pickupDateTimeStart":"Mon Oct 22 15:35:00 EDT 2018","pickupDateTimeEnd":"Mon Oct 22 15:35:00 EDT 2018","orderId":"af55680e","created":1540222561000,"retrieveType":1,"bookingId":"fddf706c2374","numberOfBags":1,"lastUpdated":1540222564000,"total":15.0,"statusId":"1","pickupAddress":"123 Mockingbird Lane, Detroit, MI, 48226","phone":"3133205178","trips":[{"itinerary":[{"origin":"JFK","destination":"DUB","flight":"EI108","departureDate":"2018-10-31T22:10:00","tripNumber":1}]},{"itinerary":[{"origin":"DUB","destination":"JFK","flight":"EI109","departureDate":"2018-11-09T16:20:00","tripNumber":2}]}],"statusName":"Requested","name":"Suki Johnson","currencyCode":"USD","email":"suki@ngeosone.com"},{"pickupDateTimeStart":"Mon Oct 22 15:44:00 EDT 2018","pickupDateTimeEnd":"Mon Oct 22 15:44:00 EDT 2018","orderId":"1eee6da0","created":1540223072000,"retrieveType":3,"bookingId":"899f4592d736","numberOfBags":1,"retrieveAddress":"Casablanca Hotel","lastUpdated":1540223073000,"total":15.0,"statusId":"1","pickupAddress":"123 Mockingbird Lane, Detroit, MI, 48226","phone":"3133205178","trips":[{"itinerary":[{"origin":"YVR","destination":"YYZ","flight":"TK9137","departureDate":"2018-10-24T12:00:00","tripNumber":1},{"origin":"YYZ","destination":"IST","flight":"TK18","departureDate":"2018-10-24T22:30:00","tripNumber":1},{"origin":"IST","destination":"BEY","flight":"TK826","departureDate":"2018-10-25T21:00:00","tripNumber":1}]},{"itinerary":[{"origin":"BEY","destination":"IST","flight":"TK827","departureDate":"2018-10-31T04:05:00","tripNumber":2},{"origin":"IST","destination":"YYZ","flight":"TK17","departureDate":"2018-10-31T15:30:00","tripNumber":2},{"origin":"YYZ","destination":"YVR","flight":"TK8694","departureDate":"2018-10-31T22:30:00","tripNumber":2}]}],"statusName":"Requested","name":"Suki Johnson","currencyCode":"USD","email":"suki@ngeosone.com"}]
    //this.order = odrs[1]
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    this.order = this.bagservice.selectedOrder

    this.updateUI()


  }

  rowClass(idx){

    if(idx % 2 == 0){
      return "even"
    }else{
      return "odd"
    }
  }

  updateUI(){
    if(this.order != undefined){
      if( this.order.bags != undefined && this.order.bags.length > 0){
        for(var i=0; i < this.order.bags.length; i++){

        }
      }else{
        for(var i=0; i < this.order.numberOfBags; i++){
          this.addBag()
        }
      }
    }
  }

  addBag(){
    if(this.order.bags == undefined){
      this.order.bags = []
    }
    this.currentBag = new BagModel()
    this.currentBag.bagId = UUID.UUID().split("-")[0];
    console.log(this.currentBag.bagId)
    this.order.bags.push(this.currentBag)
  }

  checkin(order,tripIndex){
    var req = {}
    req["orderId"] = order.orderId
    req["tripIndex"] = tripIndex
    req["bags"] = order.bags
    this.webservice.checkinBaggage(req).subscribe(data=>{
      this.order = data
    },error =>{

    })
  }

  retrievePickupOrder(orderId){
    var req = {}
    req["orderId"] =orderId
    this.webservice.retrievePickupOrder(req).subscribe(data=>{
      this.order = data
      this.updateUI()
    },error =>{

    })
  }


  addImage(bag){
    //   const options: CameraOptions = {
    //     quality: 100,
    //     destinationType: this.camera.DestinationType.DATA_URL,
    //     encodingType: this.camera.EncodingType.JPEG,
    //     mediaType: this.camera.MediaType.PICTURE
    //   }
    //
    //
    //   this.camera.getPicture(options).then((imageData) => {
    //     // imageData is either a base64 encoded string or a file URI
    //     // If it's base64 (DATA_URL):
    //     let base64Image = 'data:image/jpeg;base64,' + imageData;
    //   }, (err) => {
    //     // Handle error
    //   });
  }

  // METHODS
  // open gallery
  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
    console.info('Gallery opened at index ', index);
  }

  // callback on gallery closed
  galleryClosed() {
    console.info('Gallery closed.');
  }

  // callback on gallery image clicked
  galleryImageClicked(index) {
    console.info('Gallery image clicked with index ', index);
  }

  // callback on gallery image changed
  galleryImageChanged(index) {
    console.info('Gallery image changed to index ', index);
  }

  // callback on user clicked delete button
  deleteImage(index) {
    console.info('Delete image at index ', index);
  }

  retrieveOrders(merchantId){

  }

}
