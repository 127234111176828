import {Component, OnInit} from '@angular/core';
import * as io from 'socket.io-client';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {WebserviceService} from '../webservice.service';

@Component({
  selector: 'app-airport',
  templateUrl: './airport.component.html',
  styleUrls: ['./airport.component.css']
})
export class AirportComponent implements OnInit {
  socket;
  events = new Array()
  callingObject
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private webservice: WebserviceService, private router: Router) {
    this.route.params.subscribe( params => {

    });
    this.bagEvent.bind(this);
  }

  ngOnInit() {
    this.events = []
    this.socket = io('https://backend.travelful.co:9085/', {secure:true,
      transportOptions: {
        polling: {
          extraHeaders: {
            'io': this.uuidv4()
          }
        }
      }
    }).connect();

    this.socket.callingObject = this
    this.socket.on('connect', function () {
      // toastr.info('Waiting for messages...', 'Connected to Mesh', {
      //   tapToDismiss: true,
      //   timeOut: 10000
      // });
      console.log('Connected');
      //output('<span class="connect-msg">Connected to Aircraft </span>');

    });

    this.socket.on('flight_event', function (data) {

      console.log(JSON.parse(data));
      // toastr.info(event.message, event.title, {
      //   tapToDismiss: true,
      //   timeOut: 5000
      // });

    });

    this.socket.on('baggage_inbound', this.bagEvent );
  }

  bagEvent(data){
      console.log(JSON.parse(data));
      this.callingObject.events.push(JSON.parse(data))
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
