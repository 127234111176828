import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate} from '@angular/common';
import {WebserviceService} from '../webservice.service';
var bagPerSegment = {}

@Component({
  selector: 'app-request-pickup',
  templateUrl: './request-pickup.component.html',
  styleUrls: ['./request-pickup.component.css']
})
export class RequestPickupComponent implements OnInit {
  public pickupForm : FormGroup;
  public currentYear = 2018
  public booking ;
  public segIdx = 0
  public maxBags = 0;
  constructor(private formBuilder: FormBuilder, private route : ActivatedRoute, private webservice:WebserviceService, private router: Router) {


    this.pickupForm = this.formBuilder.group({
      bookingId: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      name: ['', Validators.required],
      numberOfBags: [1, Validators.required],
      pickupAddress: ['123 Mockingbird Lane, Detroit, MI, 48226', Validators.required],
      pickupDateTimeStart: [new Date().toISOString(), Validators.required],
      pickupDateTimeEnd: [new Date().toISOString(), Validators.required],
      retrieveType: [1, Validators.required],
      retrieveAddress: ['Casablanca Hotel'],
      comments: [''],
    });

    this.route.params.subscribe( params =>{
        // console.log(params)
        this.pickupForm.controls["bookingId"].setValue(params.bookingId)
        if(params.bookingId != undefined){
          this.retrieveBooking(params.bookingId)
        }
      }
    );


  }


  retrieveBooking(bookingId){
    var req = {}
    req["bookingId"] = bookingId
    this.webservice.retrieveBooking(req).subscribe(data=>{
      this.booking = data
      console.log(this.booking)

      if(this.booking.itinerary.baggage != undefined){

        this.booking.itinerary.baggage.forEach(function(b){
          b.BaggageInformationList.BaggageInformation.forEach(
            function(bi){
              //console.log(bi)
              bi.Segment.forEach(function(segment){
                bagPerSegment[segment.Id] = bi.Allowance
              })
            }
          )
        })

      }


      //console.log(bagPerSegment)

    },error =>{

    })
  }
  bagsForTripFlight(tripIndex, segmentIndex) {
    var ic = 0
    var ti =0
    var bagCount = 0
    this.booking.itinerary.trips.forEach(function (t) {
      var si = 0;
      t.segments.forEach(function(s){
        if(ti==tripIndex && si == segmentIndex){
          var allowance = bagPerSegment[ic];
          if(typeof  allowance !== "undefined"){
            console.log( allowance.Pieces)
            //console.log(tripIndex+"--"+segmentIndex+"="+ic)
            bagCount =  allowance.Pieces
          }

        }
        ic++
        si++
      })
      ti++
    })
    return bagCount
  }

  deliveryTypeChange(event){
    console.log(event)
  }

  requestPickup(){
    var pickupRequest = {}
    pickupRequest["requestData"] = this.pickupForm.value
    pickupRequest["bookingId"] = this.booking["bookingId"]
    pickupRequest["itinerary"] = this.booking["itinerary"]
    //TODO: HARDCODING FIRST PASSENGER
    pickupRequest["passenger"] = this.booking["passengers"][0]
    this.webservice.requestPickup(pickupRequest)
      .subscribe( data => {
        this.router.navigate(['home']);
      });
  }

  ngOnInit() {
    console.log()

  }

}
