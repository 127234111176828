import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class WebserviceService {

  constructor(private http: HttpClient) { }
  baseUrl  = 'http://10.0.0.7:8050/travelful';
  baseUrlSecurity  = 'http://localhost:8045';
  token = {};



  login(user: Account) {
    console.log(user)
    return this.http.post(this.baseUrlSecurity+'/login', user);
  }

  flights() {
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.get(this.baseUrl+'/flights');
  }

  checkinBaggage(baggage) {
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.post(this.baseUrl+'/checkinBaggage', baggage);
  }

  requestPickup(pickup) {
    debugger
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.post(this.baseUrl+'/requestPickup', pickup);
  }

  retrieveBooking(req) {
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.post(this.baseUrl+'/retrieveBooking', req);
  }

  retrievePickupOrder(req) {
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.post(this.baseUrl+'/retrievePickupOrder', req);
  }

  loginBaggageOperator(req) {
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.post(this.baseUrl+'/loginBaggageOperator', req);
  }

  pickupOrdersForMerchant(req) {
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.post(this.baseUrl+'/pickupOrdersForMerchant', req);
  }


  moreInfoBag(req) {
    // const headers = new HttpHeaders().set("Authorization", "Bearer "+this.token.accessToken);
    return this.http.post(this.baseUrl+'/moreInfoBag', req);
  }


}
