import { Component, OnInit } from '@angular/core';;
import {FormBuilder} from '@angular/forms';

import {ActivatedRoute, Router} from '@angular/router';
import {WebserviceService} from '../webservice.service';
import {BagService} from '../bag.service';
//import {OrderDetailComponent} from '../order-detail/order-detail.component';

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.css']
})
export class OrdersPageComponent implements OnInit {
  orders = undefined
  selectedOrder = undefined
  //@ViewChild(OrderDetailComponent) orderDetail: OrderDetailComponent;

  constructor(private formBuilder: FormBuilder, private route : ActivatedRoute, private webservice:WebserviceService, private router: Router, private bagservice: BagService) {
    this.route.params.subscribe( params =>{
        // console.log(params)
        //this.pickupForm.controls["bookingId"].setValue(params.bookingId)
        if(params.merchantId != undefined){
          this.retrieveOrders(params.merchantId)
        }
      }


    );
  }



  ngOnInit() {
  }

  retrieveOrders(merchantId){
    var req = {}
    req["merchantId"] =merchantId
    this.webservice.pickupOrdersForMerchant(req).subscribe(data=>{
      this.orders = data
      console.log(this.orders)
    },error =>{

    })
  }
  process(order){
    this.bagservice.selectedOrder = order
    this.router.navigate(['order-detail/'+order.orderId]);
  }
}
