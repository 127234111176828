import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {WebserviceService} from '../webservice.service';

@Component({
  selector: 'app-provider-login',
  templateUrl: './provider-login.component.html',
  styleUrls: ['./provider-login.component.css']
})
export class ProviderLoginComponent implements OnInit {
  public loginForm : FormGroup;

  constructor(private formBuilder: FormBuilder, private route : ActivatedRoute, private webservice:WebserviceService, private router: Router) {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.route.params.subscribe( params =>{
        // console.log(params)
        // this.loginForm.controls["bookingId"].setValue(params.bookingId)
        // if(params.bookingId != undefined){
        //     var req = {}
        //     req["bookingId"] = params.bookingId
        //     // webservice.retrieveBooking(req).subscribe(data=>{
        //     //     this.orders = data
        //     //     console.log(this.booking)
        //     // },error =>{
        //     //
        //     // })
        // }
      }


    );


  }



  ngOnInit() {
  }


  login(){
    var loginRequest = this.loginForm.value
    this.webservice.loginBaggageOperator(loginRequest)
      .subscribe( data => {
        this.router.navigate(['orders-page/'+data["merchantId"]]);
      }, error =>{});
  }

}
